import { Col, Row } from 'antd';
import React from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import {
  UpSquareOutlined
} from "@ant-design/icons";
import "./style.css"

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="container">
                    <Row gutter={30}>
                        <Col xs={24} sm={12}>
                            <div className="footer-content">
                                <div className="footer-des">
                                    DUG is more than just a cryptocurrency, it is also a way to connect with other dog meme lovers and make a difference in the world. Join the DUG movement today and help us make the world a more fun and inclusive place for everyone!
                                </div>
                                <div className="d-flex d-flex-center mt-15">
                                    <a href="https://x.com/dug_onsolana" target="_blank" className="mr-15"><img width={40} src="../images/social_x.svg" /></a>
                                    <a href="https://t.me/dugonsolanaa" target="_blank" className="mr-15"><img width={50} src="../images/social_tg.svg" /></a>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12}>
                            <div className="img-basic text-right">
                                <img src="../images/banner_dog.png" />
                            </div>
                        </Col>
                    </Row>
                    <div className='copy-right'>
                        © 2024 DUG • All Rights Reserved
                    </div>
                </div>
                <div className='backTop'>
                    <a href='#home'><UpSquareOutlined className="icon-backTop" /></a>
                </div>
            </footer>
        </>
    )
}
export default Footer