import { Menu, Button } from "antd";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useLocation,
} from "react-router-dom";
import {
  CloseOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import "./style.css";
import { isMobile } from "react-device-detect";

const Header = () => {


  const [offset, setOffset] = useState(0);
  const [isActive, setActive] = useState(false);
  const handleToggle = () => {
    setActive(!isActive);
  };
  const handleHideMenuMobile = () => {
    setActive(false);
  };


  useEffect(() => {
    if (isMobile) {
      const onScroll = () => setOffset(window.pageYOffset);
      // clean up code
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: false });
      return () => window.removeEventListener("scroll", onScroll);
    }
  }, []);

  let classBg: any = "";
  if (offset > 72) {
    classBg = "bg-header-menu";
  } else {
    classBg = "";
  }
  return (
    <>
      <header className="header header-desktop">
        <div className="container">
          <div className="main-header">
            <div className="main-logo">
              <div className="box-logo">
                <img src="./images/dug-logo.svg" alt="" />
              </div>
            </div>
            <div className="main-menu">
              <ul className="list-menu">
                <li>
                  <Link to="">Home</Link>
                </li>
                <li>
                  <a href="#about">About</a>
                </li>
                <li>
                  <a href="#tokenomics">Tokenomics</a>
                </li>
                <li>
                  <a href="#socials">Socials</a>
                </li>
                
              </ul>
            </div>
            <div className="main-button">
              <button
                type="button"
                onClick={() => window.open("https://x.com/dug_onsolana")}
                className="btn-basic-gradiant"
              >
                Join the community
              </button>
            </div>
          </div>
        </div>
      </header>

      <div>
        <div className={`header-mobile ${classBg}`}>
          <div className="header-left">
            <div className="box-logo">
              <Link to="/">
                <img src="./images/dug-logo.svg" alt="" className="logo-mobile" />
              </Link>
            </div>
            
          </div>
          <div className="header-center">
            <button
                type="button"
                onClick={() => window.open("https://x.com/dug_onsolana")}
                className="btn-basic"
              >
                Join the community
            </button>
          </div>
          <div className="header-right">
            <Button
              type="primary"
              onClick={handleToggle}
              className="btn-menu-mobile"
            >
              <MenuOutlined size={30} />
            </Button>
          </div>
        </div>
        <div className="menu-mobile">
          <Menu
            defaultSelectedKeys={[""]}
            defaultOpenKeys={[""]}
            mode="inline"
            theme="dark"
            className={
              !isActive
                ? "hide-menu menu-mobile-ul"
                : "show-menu menu-mobile-ul"
            }
          >
            <Menu.Item key="11" className="item-menu-clo">
              <div className={`header-mobile ${classBg}`}>
                <div className="header-left">
                  <div className="box-logo">
                    <Link to="/">
                        <img src="./images/dug-logo.svg" alt="" className="logo-mobile" />
                    </Link>
                    </div>
                </div>
                <div className="header-center">
                    <button
                        type="button"
                        onClick={() => window.open("https://x.com/dug_onsolana")}
                        className="btn-basic"
                    >
                        Join the community
                    </button>
                </div>
                <div className="header-right">
                  <Button
                    type="primary"
                    onClick={handleToggle}
                    className="btn-menu-mobile-close"
                  >
                    <CloseOutlined size={30} />
                  </Button>
                </div>
              </div>
            </Menu.Item>
            <Menu.Item key="home" className="mt-60">
              <Link to="/" onClick={handleHideMenuMobile}>
                Home
              </Link>
            </Menu.Item>
            <Menu.Item key="play">
              <a onClick={handleHideMenuMobile} href="#about">
                About
              </a>
            </Menu.Item>
            
            <Menu.Item key="tokenomics">
              <a href="#tokenomics" onClick={handleHideMenuMobile}>
                Tokenomics
              </a>
            </Menu.Item>
          
    
            
            <Menu.Item key="social1" className="social-menu">
              <div className="header-social">
                <span>
                  <a href="https://x.com/dug_onsolana" target="_blank" className="mr-15"><img width={30} src="../images/social_x.svg" /></a>
                </span>
              </div>
            </Menu.Item>
            
          </Menu>
        </div >
      </div >
    </>
  );
};
export default Header;
