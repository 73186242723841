
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import Footer from "../../component/Footer";
import Header from "../../component/Header";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Col, Row, Collapse } from "antd";
import "./style.css";
import {
  ExportOutlined,
  CopyOutlined,
  CaretRightOutlined
} from "@ant-design/icons";

const { Panel } = Collapse;



const Home = () => {

    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [{
            breakpoint: 600,
            settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            initialSlide: 2
            }
        }]
    };

    
    
  return (
    <>
        <Header />
        <div className="main-section">
            <div className="banner-home" id="home">
                <div className="container">
                    
                        <div className="wow bounceIn">
                            <div className="welcome-banner">Welcome to</div>
                            <div className="mt-15 text-center-mobile">
                                <img src="../images/dug-w.svg" />
                            </div>
                            <div className="des-banner">Skyward Quest: The Hunt for Aerial Treasures</div>
                            <div className="mt-15">
                                <button onClick={()=> window.open('https://dexscreener.com/solana/p8jgviy7qv2bp4xvroouhvkxqef3jjxffguuzcwfnxb')} className="btn-basic-gradiant mr-15 d-flex d-flex-btw d-flex-center">
                                    <span>Buy DUG now</span> <ExportOutlined className="ml-15"/>
                                </button>
                                
                            </div>
                            
                        </div>
                        <div>
                            <Row>
                                <Col xs={24} sm={12} className="wow bounceIn">
                                    <div className="mt-15 text-center-mobile">
                                        <img src="../images/rocket.svg" />
                                    </div>
                                </Col>
                                <Col xs={24} sm={12} className="wow bounceIn">
                                    <div className="banner-img-right">
                                        <img src="../images/banner_dog.png" />
                                    </div>
                                </Col>

                            </Row>
                        </div>

                </div>
            </div>
            <div className="partner-list-home wow bounceIn">
                <div className="container">
                    <Slider arrows={false} autoplay={true} {...settings}>
                        <div>
                            <div className="partner-item">
                                <img src="../images/bing-x.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/coin-w.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/birmark.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/coinx.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/bitget.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/xt.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/bing-x.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/coin-w.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/birmark.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/coinx.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/bitget.svg" />
                            </div>
                        </div>
                        <div>
                            <div className="partner-item">
                                <img src="../images/xt.svg" />
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
            <div className="aboutus" id="about">
                <div className="aboutus-home">
                    <div className="container">
                        <Row gutter={30}>
                            <Col xs={24} sm={12} className="wow bounceIn">
                                <div className="aboutus-top-img">
                                    <img src="../images/aboutus_img_1.png" />
                                </div>
                            </Col>
                            <Col xs={24} sm={12} className="wow bounceIn">
                                <div className="aboutus-info">
                                    <div className="aboutus-content-info">
                                        <div className="aboutus-info-name">
                                            <span className="main-text">About</span>
                                            <span className="sub-text">$DUG</span>
                                        </div>
                                        <div className="aboutus-info-des">
                                            Welcome to 'Skyward Quest,' an exhilarating blockchain-powered adventure set in a whimsical world among the clouds. In this game, you embark on a journey of discovery, danger, and the timeless quest for hidden treasures.
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className="about-bottom wow bounceIn">
                            <Row gutter={30}>
                                <Col xs={24} sm={6}>
                                    
                                </Col>
                                <Col xs={24} sm={12}>
                                    <div className="about-bottom-content">
                                        
                                        <Row gutter={20}>
                                            <Col xs={3}>
                                                <div className="img-basic">
                                                    <img src="../images/db.svg" />
                                                </div>
                                            </Col>
                                            <Col xs={21}>
                                                <div className="weight-400 text-white font-20">
                                                    With DUG, we have created a revolutionary digital currency that is designed for simplicity, security, and accessibility.
                                                </div>
                                            </Col>
                                            
                                        </Row>
                                        <Row gutter={20} className="mt-15">
                                            <Col xs={3}>
                                                
                                            </Col>
                                            <Col xs={18}>
                                                <div className="img-basic">
                                                    <img src="../images/ab_bottom_center.svg" />
                                                </div>
                                            </Col>
                                            <Col xs={3}>
                                                
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={24} sm={6}>
                                    <div className="about-bottom-img">
                                        <img src="../images/aboutus_bottom.png" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    
                </div>
                
            </div>
            <div className="how-to-buy">
                <div className="container">
                    <Row gutter={30}>
                        <Col xs={24} sm={8} className="wow bounceIn">
                            <div className="img-basic">
                                <img src="../images/how-to-buy.svg" />
                            </div>
                        </Col>
                        <Col xs={24} sm={16} className="wow bounceIn">
                            <div className="how-to-buy-content">
                                <div className="how-to-buy-name">
                                    <span className="main-text">How to buy</span>
                                    <span className="sub-text">$DUG</span>
                                </div>
                                <div className="how-to-buy-des">
                                    You can purchase $DUG in various different ways. We reccomend downloading Phantom Wallet, purchasing SOL, sending it to your phantom address and swapping using Phantom's in-app wallet, or on a decentralised exchange like Jupiter. You can purchase $DUG multichain using our integreated Mayan swap, or swap using Jupiter if you already own some $SOL.
                                </div>
                                <div className="mt-15">
                                    <button className="btn-basic-gradiant mr-15 d-flex d-flex-btw d-flex-center" onClick={()=> window.open('https://chromewebstore.google.com/detail/phantom/bfnaelmomeimhlpmgjnjophhpkkoljpa?pli=1')}><span>Get Phantom</span> <ExportOutlined className="ml-15"/></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="feature-block">
                <div className="container">
                    <div className="feature-title wow bounceIn">
                        <div className="feature-title-img">
                            <img src="../images/hat.png" />
                        </div>
                        <div className="feature-title-text">
                            Features
                        </div>
                    </div>
                    <Row gutter={30} className="mt-30">
                        <Col xs={24} sm={6} className="wow bounceIn">
                            <div className="feature-item">
                                <div className="ft-img">
                                    <img src="../images/f1.svg" />
                                </div>
                                <div className="ft-name">
                                    Unique Adventure
                                </div>
                                <div className="ft-des">
                                    Set sail in a vast, floating world,
                                    where every cloud and sky island holds secrets waiting to be uncovered.
                                    Inspired by the universal appeal of treasure hunting adventures, 'Skyward Quest' offers an experience filled with wonder, challenges, and excitement.
                                </div>
                                <div className="text-center mt-15">
                                    <button className="btn-basic-gradiant"><span>Add the bot</span> <ExportOutlined className="ml-15"/></button>
                                </div>
                            </div>
                            
                        </Col>
                        <Col xs={24} sm={6} className="wow bounceIn">
                            <div className="feature-item">
                                <div className="ft-img">
                                    <img src="../images/ft-2.svg" />
                                </div>
                                <div className="ft-name">
                                    Map Crafting and Exploration
                                </div>
                                <div className="ft-des">
                                    Begin your quest by gathering pieces of an ancient, mystical map. Each piece is a reward for completing engaging quests that test your wit, bravery, and ingenuity. Crafting the complete map unveils the path to the legendary "Skyward Treasure."
                                </div>
                                <div className="text-center mt-15">
                                    <button className="btn-basic-gradiant"><span>Coming Soon...</span> <ExportOutlined className="ml-15"/></button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6} className="wow bounceIn">
                            <div className="feature-item">
                                <div className="ft-img">
                                    <img src="../images/f3.svg" />
                                </div>
                                <div className="ft-name">
                                    Dynamic Encounters
                                </div>
                                <div className="ft-des">
                                    Face a variety of fantastical creatures such as sky serpents and wind insects. Choose your battles wisely,
                                    employ strategy, or find creative ways
                                    to avoid conflict in your quest
                                    for treasure.
                                </div>
                                <div className="text-center mt-15">
                                    <button className="btn-basic-gradiant"><span>Coming Soon...</span> <ExportOutlined className="ml-15"/></button>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6} className="wow bounceIn">
                            <div className="feature-item">
                                <div className="ft-img">
                                    <img src="../images/f4.svg" />
                                </div>
                                <div className="ft-name">
                                    Companionship
                                </div>
                                <div className="ft-des">
                                    Along your journey, you'll encounter and befriend unique creatures, including a loyal canine companion. These companions are more than just allies; they bring unique skills and abilities to aid you in your quest.
                                </div>
                                <div className="text-center mt-15">
                                    <button className="btn-basic-gradiant"><span>Coming Soon...</span> <ExportOutlined className="ml-15"/></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="tokenomics-block wow bounceIn" id="tokenomics">
                <div className="container">
                    <div className="tokenomics-title">
                        <div className="tokenomics-title-img">
                            <img src="../images/token_up.png" />
                        </div>
                        <div className="tokenomics-title-text">
                            Tokenomics
                        </div>
                    </div>
                    <div className="mt-40">
                        <Row gutter={30}>
                            <Col xs={24} sm={8}>
                                <div className="tokenomics-item">
                                    <span>Symbol</span>
                                    <span>$DUG</span>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div className="tokenomics-item">
                                    <span>Tax</span>
                                    <span>0/0</span>
                                </div>
                            </Col>
                            <Col xs={24} sm={8}>
                                <div className="tokenomics-item">
                                    <span>Liquidity</span>
                                    <span>Burned Forever</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="mt-20">
                        <Row gutter={30}>
                            <Col xs={24}>
                                <div className="tokenomics-item tokenomics-mb">
                                    <span>Token Address</span>
                                    <span>A3GzvBtxPm83YnDcR4enb5thqexMcMtje8CydD6wRd4T</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <div className="follow-up" id="socials" >
                <div className="container">
                    <Row gutter={30}>
                        <Col xs={24} sm={12} className="wow bounceIn">
                            <div className="img-basic">
                                <img src="../images/follow-img.svg" />
                            </div>
                        </Col>
                        <Col xs={24} sm={12} className="wow bounceIn">
                            <div className="follow-up-content">
                                <div className="follow-up-name">
                                    <div className="main-text">Follow DUG on</div>
                                    <div className="main-text">social media</div>
                                </div>
                                <div className="d-flex d-flex-center mt-15 text-center-mb">
                                    <a href="https://x.com/dug_onsolana" target="_blank" className="mr-15"><img width={50} src="../images/social_x.svg" /></a>
                                    <a href="https://t.me/dugonsolanaa" target="_blank" className="mr-15"><img width={50} src="../images/social_tg.svg" /></a>
                              
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="whychoice-block wow bounceIn">
                <div className="container">
                    <div className="whychoice-title">
                        <div className="whychoice-title-text">
                            Why choose
                        </div>
                        <div className="whychoice-title-sub-text">
                            $DUG?
                        </div>
                    </div>
                    <Row gutter={30} className="mt-30">
                        <Col xs={24} sm={6}>
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/f1.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Unparalleled Adventure
                                </div>
                                <div className="whychoice-des">
                                    Experience a world of high-flying exploration and discovery, blending whimsy with strategic gameplay.
                                </div>
                                
                            </div>
                        </Col>
                        <Col xs={24} sm={6} className="mt-sm-150">
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-2.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Dynamic Gameplay
                                </div>
                                <div className="whychoice-des">
                                    Engage in a mix of exploration, puzzle-solving, and combat, with continuous new challenges.
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6}>
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-3.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Community-Driven
                                </div>
                                <div className="whychoice-des">
                                    Shape the game's future through active community collaboration and feedback.
                                </div>
                               
                            </div>
                        </Col>
                        <Col xs={24} sm={6} className="mt-sm-150">
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-4.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Blockchain Integration
                                </div>
                                <div className="whychoice-des">
                                    Enjoy a secure, transparent experience with DUG tokens and unique NFTs, enhancing gameplay and asset ownership.
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={30} className="mt-60">
                        <Col xs={24} sm={6}>
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-5.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Regular Updates
                                </div>
                                <div className="whychoice-des">
                                    Discover ever-evolving content with new quests, treasures, and challenges.
                                </div>
                                
                            </div>
                        </Col>
                        <Col xs={24} sm={6} className="mt-sm-150">
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-6.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Accessible Gaming
                                </div>
                                <div className="whychoice-des">
                                    Suitable for all players, whether new to gaming or experienced adventurers.
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={6}>
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-7.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Safe and Fair
                                </div>
                                <div className="whychoice-des">
                                    A commitment to security and fairness ensures a trustworthy gaming environment.
                                </div>
                               
                            </div>
                        </Col>
                        <Col xs={24} sm={6} className="mt-sm-150">
                            <div className="whychoice-item">
                                <div className="whychoice-img">
                                    <img src="../images/choice-8.svg" />
                                </div>
                                <div className="whychoice-name">
                                    Innovative Experience
                                </div>
                                <div className="whychoice-des">
                                    'DUG' is more than a game; it's an invitation to a thrilling, evolving adventure.
                                </div>
                                
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>

            {/* <div className="fqa-block wow bounceIn" >
                <div className="container">
                    <div className="fqa-title">
                        <div className="fqa-title-img">
                            <img src="../images/faq.png" />
                        </div>
                        <div className="fqa-title-text">
                            FAQ
                        </div>
                    </div>
                    <div className="mt-40">
                        <Collapse
                            bordered={false}
                            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                            className="collapse-custom"
                        >
                            <Panel header="How do I Buy and Sell $DUG?" key="1" className="site-collapse-custom-panel">
                            <p>How do I Buy and Sell $DUG?</p>
                            </Panel>
                            <Panel header="Will $DUG list on more CEX's?" key="2" className="site-collapse-custom-panel">
                            <p>Will $DUG list on more CEX's?</p>
                            </Panel>
                            <Panel header="Is there a $DUG whitepaper / roadmap?" key="3" className="site-collapse-custom-panel">
                            <p>Is there a $DUG whitepaper / roadmap?</p>
                            </Panel>
                        </Collapse>
                    </div>
                    
                </div>
            </div> */}
            
        </div>
        
        <Footer />
    </>
  );
};
export default Home;
